import { slugify } from '@pretto/website/src/templates/blocs/lib/slugify'
import { Colors } from '@pretto/website/src/templates/blocs/rows/rowRates/types/colors.type'
import { Rates } from '@pretto/website/src/templates/blocs/rows/rowRates/types/rates'

import { ThemeProvider } from 'styled-components'

import * as S from './RowRates.styles'

export type RowRatesProps = {
  colors: Colors
  legend?: string
  ratesProps: Omit<Rates, 'style'>
  suptitle?: string
  text?: string | React.ReactNode
  title?: string
}

export const RowRates: React.FC<RowRatesProps> = ({ colors, legend, ratesProps, suptitle, text, title, ...props }) => (
  <ThemeProvider theme={{ rowColors: colors }}>
    <S.NewBlocRow {...props}>
      <S.Container>
        {suptitle && <S.Suptitle>{suptitle}</S.Suptitle>}

        {title && <S.Title id={slugify(title)}>{title}</S.Title>}

        {text && <S.Text>{text}</S.Text>}

        <S.RatesSection
          style={{ formBackgroundColor: colors.formColor, ratesPerDurationBorder: colors.border }}
          {...ratesProps}
        />

        {legend && <S.Legend>{legend}</S.Legend>}
      </S.Container>
    </S.NewBlocRow>
  </ThemeProvider>
)
