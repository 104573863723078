import { useBreakpoint } from '@pretto/bricks/assets/utility'

import { LANDING_PAGE_SEO } from '@pretto/website/src/config/pagesUrls'
import { RowRates } from '@pretto/website/src/templates/blocs/rows/rowRates/components/RowRates/RowRates'
import { Border } from '@pretto/website/src/templates/blocs/rows/rowRates/types/border.type'
import { RatesPayload } from '@pretto/website/src/templates/blocs/types/RatesPayload.type'
import { ratesProfileRegion } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesProfileRegion'
import { useRatesForm } from '@pretto/website/src/templates/tauxImmobilier/lib/useRatesForm'

const COLORS_CONFIG = {
  mobile: {
    light: { id: 'light', backgroundColor: 'white', formColor: 'neutral4', border: Border.SOLID },
    dark: { id: 'dark', backgroundColor: 'neutral4', formColor: 'neutral4', border: Border.SHADOW },
  },
  desktop: {
    light: { id: 'light', backgroundColor: 'white', formColor: 'neutral4', border: Border.SOLID },
    dark: { id: 'dark', backgroundColor: 'neutral4', formColor: 'neutral3', border: Border.SHADOW },
  },
}

type RowRatesContainerProps = {
  ctaLabel: string
  ctaUrl: string
  help: string
  isColored?: boolean
  legend?: string
  modified: string
  mortgageDuration?: string
  mortgagorsNumber?: string
  mortgagorsWages?: string
  propertyPrice?: number
  propertyRegion?: string
  publishedDate: string
  rates: RatesPayload
  suptitle?: string
  text: string
  title: string
}

export const RowRatesContainer: React.FC<RowRatesContainerProps> = ({
  ctaLabel,
  ctaUrl,
  help,
  isColored,
  legend,
  mortgageDuration,
  mortgagorsNumber,
  mortgagorsWages,
  propertyPrice,
  propertyRegion,
  rates,
  suptitle,
  text,
  title,
  ...props
}) => {
  const { breakpoint } = useBreakpoint()
  const colorsToUse = breakpoint === 'laptop' || breakpoint === 'desktop' ? COLORS_CONFIG.desktop : COLORS_CONFIG.mobile

  const { values, form } = useRatesForm({
    preSelectedValues: {
      goodPrice: propertyPrice || undefined,
      mortgagors: mortgagorsNumber ? parseInt(mortgagorsNumber, 10) : undefined,
      region: propertyRegion,
      wagesIndex: mortgagorsWages ? parseInt(mortgagorsWages, 10) : undefined,
    },
    rates: rates.marketRatesProfileLatest,
  })

  const ratesToUse = ratesProfileRegion(rates.marketRatesProfileRegionLatest, {
    ...values,
    defaultDuration: mortgageDuration ? parseInt(mortgageDuration, 10) : undefined,
  })

  const rowProps = {
    colors: isColored ? colorsToUse.dark : colorsToUse.light,
    legend,
    ratesProps: {
      cta: ctaLabel,
      form,
      help,
      rates: ratesToUse,
      url: ctaUrl ?? LANDING_PAGE_SEO,
    },
    suptitle,
    text,
    title,
  }

  return <RowRates {...rowProps} {...props} />
}
