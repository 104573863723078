import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { NewBlocRow as NewBlocRowComponent } from '@pretto/website/src/templates/blocs/components/NewBlocRow'
import { RatesSection as RateSectionComponent } from '@pretto/website/src/templates/blocs/rows/rowRates/components/RatesSection'
import { anchorStyle } from '@pretto/website/src/templates/blocs/styles/anchorStyle'

import styled from 'styled-components'

type IsColoredProps = {
  $isColored?: boolean
  $isFormColorDark?: boolean
}

export const NewBlocRow = styled(NewBlocRowComponent)<IsColoredProps>`
  ${grid()};
  background-color: ${({ theme }) => theme.colors[theme.rowColors.backgroundColor]};
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    left: -100%;
    right: -100%;
    height: ${({ theme }) => (theme.id === 'dark' ? '100%' : g(5))};
    background-color: ${({ theme }) => theme.colors[theme.rowColors.formColor]};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    &::before {
      height: ${({ theme }) => (theme.id === 'dark' ? '100%' : '50%')};
    }
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    &::before {
      content: none;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${column([1, 6])};
  z-index: 2;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`

export const Suptitle = styled.div`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(2)};
  padding: 0 ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: unset;
  }
`

export const Title = styled.div`
  ${anchorStyle};
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(2)};
  padding: 0 ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: unset;
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.typos.body4};
  margin-bottom: ${g(3)};
  padding: 0 ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(4)};
    padding: unset;
  }
`
export const RatesSection = styled(RateSectionComponent)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      left: -100%;
      right: -100%;
      height: 75%;
      background-color: ${({ theme }) => theme.colors[theme.rowColors.formColor]};
    }
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    &::before {
      content: none;
    }
  }
`

export const Legend = styled.div<IsColoredProps>`
  ${({ theme }) => theme.typos.body4};
  padding: 0px ${g(2, 4)};
  background-color: ${({ theme }) => theme.colors[theme.rowColors.formColor]};

  z-index: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: unset;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    background-color: unset;
    margin-top: ${g(3)};

    &::before {
      content: none;
    }
  }
`
